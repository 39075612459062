.Login{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    &_container{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        border-radius: 1rem;
        padding: 5rem 3rem;
        background: #FFF;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

        &_form{
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }
}