.Form_login{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.input-wrapper{
    height: 100%;
    width: 100%;
    
    &_label{
        color: #A39EB8;
        height: 100%;
        display: flex;
        font-size: 1.5rem;
        align-items: center;
    }

    &_input{
        border: none;
        height: 100%;
        width: calc(100% - 1rem);
        font-size: 1.5rem;
        padding: 1rem 0.5rem;
        background-color: #EDEBF2;
        border-radius: 1rem;
    }
}

.sign-in-button{
    border: none;
    background-color: #694BE3;
    padding: 2rem 0rem;
    border-radius: 1rem;
    transition: all .25s ease-in-out;
    color: white;
    font-size: 2rem;
    &:hover{
        background-color: #5641AC; 
    }
}