.AddProduct{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    &_container{
        padding-top: 3rem;
        width: 1440px;

        & h1{ 
            padding: 2rem 0rem;
        }
    }
}